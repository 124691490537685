<template>    
    <nav class="navbar navbar-expand-lg">
		<div class="container">
            <router-link :to="{hash:'#home'}" class="logo" title="PSD to CMS"> <img  v-lazy="`${publicPath}images/logo-light.png`" alt="logo"> </router-link>

		  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		    <span class="icon-bar"><i class="fas fa-bars"></i></span>
		  </button>

		  <!-- navbar links -->
		  <div class="collapse navbar-collapse" id="navbarSupportedContent">
		    <ul class="navbar-nav ml-auto">
		      <li class="nav-item">
                  <router-link :to="{hash:'#home'}" class="nav-link active" title="Home"> Home </router-link>
		      </li>
		      <li class="nav-item">
                <router-link :to="{hash:'#services'}" class="nav-link" title="Service"> Services </router-link>
              </li>
		      <li class="nav-item">
                <router-link :to="{hash:'#about'}" class="nav-link" title="About"> About </router-link>
              </li>
		      <li class="nav-item">
		        <router-link :to="{hash:'#contact'}" class="nav-link" title="Contact"> Contact </router-link>
		      </li>
		    </ul>
		  </div>
		</div>
	</nav>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
